@import '/src/styles/utilities.scss';

.showNewsletterSignUp {
  @include create-container();

  position: relative;
  display: flex;
  flex-direction: column;
  gap: $space-16;

  @include media-breakpoint-only(xs) {
    padding: $space-20 $space-48;
  }

  @include media-breakpoint-only(sm) {
    padding: $space-24 $space-44;
  }

  @include media-breakpoint-up(md) {
    padding: $space-24 0 $space-36 0;
  }

  &:before {
    position: absolute;
    top: 0;
    left: calc(-50vw + 50%);
    width: 100vw;
    content: '';
    border-top: 1px solid var(--color-gray-300);
  }
}
