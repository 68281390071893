@import '/src/styles/utilities.scss';

.showFeatureAbout {
  @include create-container();

  display: grid;
  gap: $space-32;
  padding: $space-32 0;

  @include media-breakpoint-up(lg) {
    gap: $space-68;
    padding: $space-68 0;
  }
}
