@import '/src/styles/utilities.scss';

.showFeatureCharacters {
  padding: $space-68 0;
  background-color: var(--color-blue-100);
}

.showFeatureCharacters__heading {
  margin-bottom: $space-48;
  text-align: center;

  @include media-breakpoint-up(md) {
    text-align: left;
  }
}

.showFeatureCharacters__characters {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: $space-48;

  @include media-breakpoint-up(md) {
    grid-template-columns: 1fr 1fr;
    column-gap: $space-16;
  }
}
