@import '/src/styles/utilities.scss';

.showPopularComics {
  // This is a little easier than grid/flex because the comics have overflow that is difficult to manage and all we needed was spacing between them.
  > * + * {
    margin-top: $space-48;
  }

  margin-bottom: $space-56;
  @include media-breakpoint-up(lg) {
    margin-bottom: $space-96;
  }
}

.showPopularComics__badge {
  margin-bottom: $space-20;
}

.showPopularComics__comic {
  overflow: hidden;
}

.showPopularComics__mobileAd {
  @include media-breakpoint-up(lg) {
    display: none;
  }
}
